import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getPaymentMethod as onGetPaymentMethodList,
    addNewPaymentMethodList as onAddNewPaymentMethodList,
    updatePaymentMethodList as onUpdatePaymentMethodList,
    deletePaymentMethodList as onDeletePaymentMethodList,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
    Button,
    FormGroup,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

const PaymentMethod = () => {

    //meta title
    document.title = "Payment Method | Praavi.com Admin Portal";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
         
            method_name: (paymentMethod && paymentMethod.method_name) || '',
            status: !!isEdit ? paymentMethod && paymentMethod.status===1?true:false  : true,
            
        },
        validationSchema: Yup.object({
            method_name: Yup.string().required("Please Enter Your Payment Method")
        }),
        onSubmit: async (values) => {
            if (isEdit) {
                const payload = {
                    method_name:values.method_name,
                    status: values.status?1:0,
                };
                // update Job
                

               await dispatch(onUpdatePaymentMethodList({id:paymentMethod.id,data:payload}));
                validation.resetForm();
            } else {
                const payload = {
                    method_name:values.method_name,
                    status: values.status?1:0,
                };
                // save new Job
               await dispatch(onAddNewPaymentMethodList(payload));
                validation.resetForm();
            }
           await    dispatch(onGetPaymentMethodList());
            toggle();
        },
    });

    const dispatch = useDispatch();

    const PaymentMethodsProperties = createSelector(
        (state) => state.paymentMethodReducer,
        (paymentMethodReducer) => ({
            paymentMethods: paymentMethodReducer.paymentMethods,
            loading: paymentMethodReducer.loading
        })
    );

    const {
        paymentMethods, loading
    } = useSelector(PaymentMethodsProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {
   
            dispatch(onGetPaymentMethodList());
        
    }, [dispatch]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setPaymentMethod(null);
            setIsEdit(false);
        } else {
            setModal(true);
        }
        validation.resetForm()
    };

    const handlePaymentMethodClick = arg => {
   console.log(arg,"arg")
        setPaymentMethod(arg);

        setIsEdit(true);

        toggle();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setPaymentMethod(job);
        setDeleteModal(true);
    };

    const handleDeletePaymentMethod = async () => {
        if (paymentMethod && paymentMethod.id) {
          await  dispatch(onDeletePaymentMethodList(paymentMethod.id));
            setDeleteModal(false);
           await dispatch(onGetPaymentMethodList());
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "s.no.",
                enableColumnFilter: false,
            
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{ cellProps.row.index + 1 }</Link>
                }
            },
            {
                header: "Method Name",
                accessorKey: "method_name",
                enableColumnFilter: false,
             
            },
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Deactive</span>;
                     }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id:"pamentmethod-action",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                       
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handlePaymentMethodClick(jobData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            {/* <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletePaymentMethod}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this payment method?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Payment Method" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Payment Method</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to={void(0)} onClick={() => setModal(true)} className="btn btn-primary me-1">Add</Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={paymentMethods || []}
                                                // isCustomPageSize={true}
                                                isGlobalFilter={true}
                                                // isJobListGlobalFilter={true}
                                                isPagination={true}
                                                SearchPlaceholder="Your Search"
                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
                                                pagination="pagination"
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit" : "Add"} Payment Method
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label> Payment Method Name</Label>
                                            <Input
                                                name="method_name"
                                                type="text"
                                                placeholder="Please enter payment term name."
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.method_name || ""}
                                                invalid={
                                                    validation.touched.method_name && validation.errors.method_name ? true : false
                                                }
                                            />
                                            {validation.touched.method_name && validation.errors.method_name ? (
                                                <FormFeedback type="invalid">{validation.errors.method_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <FormGroup switch>
                                                <Label className=""> {validation.values.status === true?'Active':'Deactive'}
                                                <Input
                                                    name="status"
                                                    type="switch"
                                                    role="switch"
                                                    checked={validation.values.status === true}

                                                    onClick={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={ validation.values.status }

                                                />

                                                </Label>
                                          
                                            </FormGroup>


                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default PaymentMethod;