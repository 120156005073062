import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCategory as onGetCategoryList,
    // addNewJobList as onAddNewJobList,
    addNewCategoryList as onAddNewCategoryList,
    updateCategoryList as onUpdateCategoryList,
    deleteCategoryList as onDeleteCategoryList,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
    Button,
    FormGroup,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { getCategoryDropdown } from "services/MasterServices";

const SubCategory = () => {

    //meta title
    document.title = "Subcategory List | Praavi.com - Admin Portal";

    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [category, setCategory] = useState(null);
    const [categoryDropdownList, setCategoryDropdownList] = useState([])

    // validation
    const validationCreate = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            category_id: "",
            subcategory_name: '',
            status: true
        },
        validationSchema: Yup.object({
            category_id: Yup.string().required("Please Choose Category."),
            subcategory_name: Yup.string().required("Please fill Subcategory Name.")
        }),
        onSubmit: async (values) => {

            const payload = {
                parent_id: Number(values['category_id']),
                category_name: values["subcategory_name"],
                status: values["status"] ? 1 : 0
            };
            // save new SubCategory

            console.log(payload, "payload")
            dispatch(onAddNewCategoryList(payload));
            validationCreate.resetForm();

            toggle();

            let params = {
                parent_type: "subcategory",
                status: "active"
            }
            await dispatch(onGetCategoryList({ params }));
        },
    });


    const validationEdit = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (category && category.id) || '',
            category_id: category?.parent_id,
            subcategory_name: (category && category.category_name) || '',
            status: (category && category.status === 1  ? true : false) ,

        },
        validationSchema: Yup.object({
            category_id: Yup.string().required("Please Choose Category."),
            subcategory_name: Yup.string().required("Please fill Subcategory Name")
        }),
        onSubmit: async (values) => {

            const payload = {
                id: values["id"],
                parent_id: Number(values['category_id']),
                category_name: values["subcategory_name"],
                status: values["status"] ? 1 : 0
            };
            // update Job
            await dispatch(onUpdateCategoryList(payload));
            toggleEdit();

            let params = {
                parent_type: "subcategory",
                status: "active"
            }
            await dispatch(onGetCategoryList({ params }));
            // validationEdit.resetForm();

            

        },
    });


    const dispatch = useDispatch();

    const categoryProperties = createSelector(
        (state) => state.category,
        (category) => ({
            categoryList: category.categoryList,
            loading: category.loading
        })
    );

    const {
        categoryList, loading
    } = useSelector(categoryProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {
        // if (categoryList && !categoryList.length) {
        let params = {
            parent_type: "subcategory",
            status: "active"
        }
        dispatch(onGetCategoryList({ params }));
        // }
    }, [dispatch]);

    useEffect(() => {
        getCategoryList()
    }, []);


    const getCategoryList = async () => {
        let params = {
            status: 'active',
            parent_type: 'parent',
            limit: 100
        }

        try {
            await getCategoryDropdown({ params }).then(response => {
                console.log(response)

                setCategoryDropdownList(response)


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }

    const toggle = () => {
        if (modal) {
            setModal(false);
            setCategory(null);
        } else {
            setModal(true);
        }
    };

    const toggleEdit = () => {
        if (modalEdit) {
            setModalEdit(false);
            setCategory(null);
            validationEdit.resetForm();
        } else {
            setModalEdit(true);
        }
    };

    const handleEditClick = arg => {
        const category = arg;
        console.log(category,"category")
        setCategory(category);

        setIsEdit(true);



        toggleEdit();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (category) => {
        setCategory(category);
        setDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        if (category && category.id) {
            dispatch(onDeleteCategoryList(category.id));
            setDeleteModal(false);
            dispatch(onGetCategoryList());
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'Sl.No',
                accessorKey: "id",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{cellProps.row.index +1}</Link>
                }
            },
            {
                header: "Category Name",
                accessorKey: "parent.category_name",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Subcategory Name",
                accessorKey: "category_name",
                enableColumnFilter: false,
                enableSorting: false,


            },

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Deactive</span>;
                     }
                },
            },
            {
                     
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id:"subcategory-action",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">


                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handleEditClick(jobData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this subcategory?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Subcategory List" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Subcategory List</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Add</Link>

                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={categoryList || []}
                                                // isCustomPageSize={true}
                                                isGlobalFilter={true}
                                                // isJobListGlobalFilter={true}
                                                isPagination={true}
                                                SearchPlaceholder="Your Search"
                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
                                                pagination="pagination"
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            Add
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validationCreate.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>Choose Category</Label>
                                            <Input
                                                name="category_id"
                                                type="select"
                                                className="form-select"
                                                onChange={validationCreate.handleChange}
                                                onBlur={validationCreate.handleBlur}
                                                value={
                                                    validationCreate.values.category_id || ""
                                                }
                                                invalid={
                                                    validationCreate.touched.category_id && validationCreate.errors.category_id
                                                        ? true
                                                        : false
                                                }
                                            >

                                                <option value="">Select</option>

                                                {
                                                    categoryDropdownList?.map((item, key) =>
                                                        <option key={`dropdown-${key}`} value={item?.id}>{item?.category_name}</option>


                                                    )
                                                }


                                            </Input>
                                            {validationCreate.touched.category_id && validationCreate.errors.category_id ? (
                                                <FormFeedback type="invalid">{validationCreate.errors.category_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label> Subcategory Name</Label>
                                            <Input
                                                name="subcategory_name"
                                                type="text"
                                                placeholder="Please enter category name."
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validationCreate.handleChange}
                                                onBlur={validationCreate.handleBlur}
                                                value={validationCreate.values.subcategory_name || ""}
                                                invalid={
                                                    validationCreate.touched.subcategory_name && validationCreate.errors.subcategory_name ? true : false
                                                }
                                            />
                                            {validationCreate.touched.subcategory_name && validationCreate.errors.subcategory_name ? (
                                                <FormFeedback type="invalid">{validationCreate.errors.subcategory_name}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <FormGroup switch>
                                                <Label className=""> {validationCreate.values.status === true?'Active':'Deactive'}
                                                    <Input
                                                        name="status"
                                                        type="switch"
                                                        role="switch"
                                                        checked={validationCreate.values.status === true}

                                                        onClick={validationCreate.handleChange}
                                                        onBlur={validationCreate.handleBlur}
                                                        value={validationCreate.values.status || true}

                                                    />

                                                </Label>

                                            </FormGroup>


                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={modalEdit} toggle={toggleEdit}>
                        <ModalHeader toggle={toggleEdit} tag="h4">
                            Edit SubCategory
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validationEdit.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>Choose Category</Label>
                                            <Input
                                                name="category_id"
                                                type="select"
                                                className="form-select"
                                                onChange={validationEdit.handleChange}
                                                onBlur={validationCreate.handleBlur}
                                                value={
                                                    validationEdit.values.category_id
                                                }
                                                invalid={
                                                    validationEdit.touched.category_id && validationEdit.errors.category_id
                                                        ? true
                                                        : false
                                                }
                                            >

                                                <option value="">Select</option>

                                                {
                                                    categoryDropdownList?.map((item, key) =>
                                                        <option key={`dropdown-${key}`} value={item?.id}>{item?.category_name}</option>


                                                    )
                                                }


                                            </Input>
                                            {validationEdit.touched.category_id && validationEdit.errors.category_id ? (
                                                <FormFeedback type="invalid">{validationEdit.errors.category_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label> Subcategory Name</Label>
                                            <Input
                                                name="subcategory_name"
                                                type="text"
                                                placeholder="Subcategory Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validationEdit.handleChange}
                                                onBlur={validationEdit.handleBlur}
                                                value={validationEdit.values.subcategory_name || ""}
                                                invalid={
                                                    validationEdit.touched.subcategory_name && validationEdit.errors.subcategory_name ? true : false
                                                }
                                            />
                                            {validationEdit.touched.subcategory_name && validationEdit.errors.subcategory_name ? (
                                                <FormFeedback type="invalid">{validationEdit.errors.subcategory_name}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                        <Label>Status</Label>
                                            <FormGroup switch>
                                                <Label className=""> {validationEdit.values.status?'Active':'Deactive'}
                                                    
                                                    <Input
                                                        name="status"
                                                        type="switch"
                                                        role="switch"
                                                        checked={validationEdit.values.status === true}

                                                        onClick={validationEdit.handleChange}
                                                        onBlur={validationEdit.handleBlur}
                                                        value={validationEdit.values.status || true}

                                                    />

                                                </Label>

                                            </FormGroup>


                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default SubCategory;