import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";


//pass new generated access token here
// const token = accessToken;



//apply base url for axios
const API_URL = process.env.REACT_APP_BASEPATH;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// if(token){
//   axiosApi.defaults.headers.common["Authorization"] = token;
// }

/*
 * Add a response interceptor
 */
axiosApi.interceptors.request.use(
  (config) => {

    const authUser = localStorage.getItem("authUser");
    if(authUser){
      const {access_token} = JSON.parse(authUser)      
      if (access_token) config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {

    if (error && error.response && error.response.status === 401) {
      if (window.location.pathname != '/login' || window.location.pathname == '') {
        window.localStorage.clear();
        window.location.href = '/login';
      }
    }
    
    return Promise.reject(error)
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  console.log("calling",config)
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
