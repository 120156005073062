import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';


import {
    getVendorTypeAction as onGetVendorTypeList,
    updateVendorTypeAction as onUpdateVendorTypeList,

} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    Badge,
    Button,
    FormGroup,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

const VendorType = () => {

    //meta title
    document.title = "Vendor Type | Praavi.com - Admin Portal";

    const [modal, setModal] = useState(false);
    const [vendorTypeState, setVendorTypeState] = useState(null);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            type_name: (vendorTypeState && vendorTypeState.type_name) || '',
            seq_no: (vendorTypeState && vendorTypeState.seq_no) || '',
            status: vendorTypeState && vendorTypeState.status === 1  ? true : false ,

        },
        validationSchema: Yup.object({

            type_name: Yup.string().required("Please Enter Vendor Type Name"),

        }),
        onSubmit: (values) => {

            const payload = {

                type_name: values["type_name"],
                seq_no: values["seq_no"],
                status: values["status"] ? 1 : 0,

            };
            // save new Job
            dispatch(onUpdateVendorTypeList({ id: vendorTypeState.id, data: payload }));
            validation.resetForm();

            toggle();
        },
    });

    const dispatch = useDispatch();

    const VendorTypeProperties = createSelector(
        (state) => state.vendorTypeReducer,
        (vendorTypeReducer) => ({
            vendorTypeList: vendorTypeReducer.vendorTypeList,
            loading: vendorTypeReducer.loading
        })
    );

    const {
        vendorTypeList, loading
    } = useSelector(VendorTypeProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {

        dispatch(onGetVendorTypeList());

    }, [dispatch]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setVendorTypeState(null);
        } else {
            setModal(true);
        }
    };

    const handleJobClick = arg => {

        setVendorTypeState(arg
        );

        toggle();
    };


    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{cellProps.row.index + 1}</Link>
                }
            },
            {
                header: "Type Name",
                accessorKey: "type_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Sequence No',
                accessorKey: "seq_no",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Deactive</span>;
                     }
                },
            },
            {
                     
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id:"vendor-type-action",
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">


                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handleJobClick(jobData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>


                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Vendor Type" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Vendor Type</h5>

                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={vendorTypeList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                SearchPlaceholder="Your Search"
                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
                                                pagination="pagination"
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {"Edit Vendor Type"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label> Type Name</Label>
                                            <Input
                                                name="type_name"
                                                type="text"
                                                placeholder="Insert Job Id"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.type_name || ""}
                                                invalid={
                                                    validation.touched.type_name && validation.errors.type_name ? true : false
                                                }
                                            />
                                            {validation.touched.type_name && validation.errors.type_name ? (
                                                <FormFeedback type="invalid">{validation.errors.type_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Sequence Number</Label>
                                            <Input
                                                name="seq_no"
                                                type="number"
                                                placeholder="Insert Job Title"

                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.seq_no || ""}

                                            />

                                        </div>
                                 

                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <FormGroup switch>
                                                <Label className=""> {validation.values.status === true?'Active':'Deactive'}
                                                    <Input
                                                        name="status"
                                                        type="switch"
                                                        role="switch"
                                                        checked={validation.values.status === true}

                                                        onClick={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.status || true}

                                                    />

                                                </Label>

                                            </FormGroup>


                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default VendorType;