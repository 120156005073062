import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getPaymentTerm as onGetPaymentTermList,
    addNewPaymentTermList as onAddNewPaymentTermList,
    updatePaymentTermList as onUpdatePaymentTermList,
    deletePaymentTermList as onDeletePaymentTermList,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
    Button,
    FormGroup,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

const PaymentTerm = () => {

    //meta title
    document.title = "Payment Term | Praavi.com Admin Portal";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [paymentTerm, setPaymentTerm] = useState(null);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
         
            term_name: (paymentTerm && paymentTerm.term_name) || '',
            term_for: (paymentTerm && paymentTerm.term_for) || '',
            days: (paymentTerm && paymentTerm.days) || '',
            status: !!isEdit ? (paymentTerm && paymentTerm.status === 1 ? true:false)  : true,
            
        },
        validationSchema: Yup.object({
            term_name: Yup.string().required("Please Enter Your Payment Term."),
            term_for: Yup.string().required("Please Enter Your Payment For."),
            days: Yup.string().required("Please Enter Your Payment days."),
        }),
        onSubmit: async (values) => {
            if (isEdit) {
                const payload = {
                    term_name:values.term_name,
                    status: values.status?1:0,
                    days:values.days,
                    term_for:values.term_for
                };
                // update Job
                

               await dispatch(onUpdatePaymentTermList({id:paymentTerm.id,data:payload}));
                validation.resetForm();
            } else {
                const payload = {
                    term_name:values.term_name,
                    status: values.status?1:0,
                    days:values.days,
                    term_for:values.term_for
                };
                // save new Job
               await dispatch(onAddNewPaymentTermList(payload));
                validation.resetForm();
            }
           await    dispatch(onGetPaymentTermList());
            toggle();
        },
    });

    const dispatch = useDispatch();

    const PaymentTermsProperties = createSelector(
        (state) => state.paymentTermReducer,
        (paymentTermReducer) => ({
            paymentTerms: paymentTermReducer.paymentTerms,
            loading: paymentTermReducer.loading
        })
    );

    const {
        paymentTerms, loading
    } = useSelector(PaymentTermsProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {
   
            dispatch(onGetPaymentTermList());
        
    }, [dispatch]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setPaymentTerm(null);
            setIsEdit(false);
        } else {
            setModal(true);
        }
        validation.resetForm()
    };

    const handlePaymentTermClick = arg => {
   console.log(arg,"arg")
        setPaymentTerm(arg);

        setIsEdit(true);

        toggle();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setPaymentTerm(job);
        setDeleteModal(true);
    };

    const handleDeletePaymentTerm = async () => {
        if (paymentTerm && paymentTerm.id) {
          await  dispatch(onDeletePaymentTermList(paymentTerm.id));
            setDeleteModal(false);
           await dispatch(onGetPaymentTermList());
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "s.no.",
                enableColumnFilter: false,
            
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{ cellProps.row.index + 1 }</Link>
                }
            },
            {
                header: "Term Name",
                accessorKey: "term_name",
                enableColumnFilter: false,
             
            },
            {
                header: "Term For",
                accessorKey: "term_for",
                enableColumnFilter: false,
             
            },
            {
                header: "Days",
                accessorKey: "days",
                enableColumnFilter: false,
             
            },
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Deactive</span>;
                     }
                },
            },
            {
               
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id:"payment-term-action",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                       
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handlePaymentTermClick(jobData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletePaymentTerm}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this payment term?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Payment Term" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Payment Term</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to={void(0)} onClick={() => setModal(true)} className="btn btn-primary me-1">Add </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={paymentTerms || []}
                                                // isCustomPageSize={true}
                                                isGlobalFilter={true}
                                                // isJobListGlobalFilter={true}
                                                isPagination={true}
                                                SearchPlaceholder="Your Search"
                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
                                                pagination="pagination"
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit" : "Add"} Payment Term
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label> Payment Term Name</Label>
                                            <Input
                                                name="term_name"
                                                type="text"
                                                placeholder="Please enter payment term name."
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.term_name || ""}
                                                invalid={
                                                    validation.touched.term_name && validation.errors.term_name ? true : false
                                                }
                                            />
                                            {validation.touched.term_name && validation.errors.term_name ? (
                                                <FormFeedback type="invalid">{validation.errors.term_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label> Term for </Label>
                                            <Input
                                                name="term_for"
                                                type="select"
                                                placeholder="Please enter payment term for."
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.term_for || ""}
                                                invalid={
                                                    validation.touched.term_for && validation.errors.term_for ? true : false
                                                }
                                            >
                                                <option value="">Select</option>
                                                <option value="sell">Sell</option>
                                                <option value="payment">Payment</option>
                                            

                                            </Input>
                                            {validation.touched.term_for && validation.errors.term_for ? (
                                                <FormFeedback type="invalid">{validation.errors.term_for}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Days</Label>
                                            <Input
                                                name="days"
                                                type="text"
                                                placeholder="Please enter days."
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.days || ""}
                                                invalid={
                                                    validation.touched.days && validation.errors.days ? true : false
                                                }
                                            />
                                            {validation.touched.days && validation.errors.days ? (
                                                <FormFeedback type="invalid">{validation.errors.days}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <FormGroup switch>
                                                <Label className=""> {validation.values.status === true?'Active':'Deactive'}
                                                <Input
                                                    name="status"
                                                    type="switch"
                                                    role="switch"
                                                    checked={validation.values.status === true}

                                                    onClick={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={ validation.values.status }

                                                />

                                                </Label>
                                          
                                            </FormGroup>


                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default PaymentTerm;