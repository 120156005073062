import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getJobList as onGetJobList,
    addNewJobList as onAddNewJobList,
    updateJobList as onUpdateJobList,
    deleteJobList as onDeleteJobList,
} from "store/actions";


import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { deleteClient, getClientList } from "services/ClientManagementServices";
import Paginations from "components/Common/Pagination";
import moment from "moment";
import { isEmpty } from "lodash";

const ClientList = () => {

    //meta title
    document.title = "Client List | Praavi.com - Admin Portal";


    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [job, setJob] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState([])


    useEffect(() => {
        getClientListRecord()
    }, [limit, page])


    const getClientListRecord = async (search) => {
        try {
            setLoading(true)

            let params = {
                page: page,
                limit: limit,
                search: search ?? ''
            }

            await getClientList(params).then(response => {

                console.log(response)

                if (response.data) {

                    const { meta, data } = response;
                    setClients(data ?? [])
                    // setTotalCount(Math.ceil(meta?.itemCount / meta?.limit));
                    setTotalCount(meta?.itemCount);

                }
                setLoading(false)

            }).catch(err => console.log(err))

        } catch (error) {
            setLoading(false)
        }
    }






    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        if (job && job.id) {

            try {
                await deleteClient(job.id).then(response => {
                    console.log(response)
                    // if(response){
                    toast.success("Client is deleted successfully.", { autoClose: 500 });
                    // }
                    getClientListRecord()
                }).catch(err => console.log(err))
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 500 });
            }


            setDeleteModal(false);
        }
    };


    const handleSearch = () => {
        setPage(1);
        getClientListRecord(search)
    }

    const handleModal = (data) => {
        setModal(true);
        let temp = data
        setSelectedItem(temp)
    }

    const toggle = () => setModal(!modal);


    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{(page - 1) * limit + (cellProps.row.index + 1)}</Link>
                }
            },
            {
                header: "Client Name",
                accessorKey: "client_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Registration Date',
                accessorKey: "reg_date",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <span>{moment(cellProps.row.original.reg_date).format("DD-MM-YYYY")}</span>
                }

            },
            {
                header: 'Client Type',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "clientType.type_name"
            },
            {
                header: 'Payment Term',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "paymentTerm.term_name"
            },

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Deactive</span>;
                    }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "client-action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">


                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to={void (0)} className="btn btn-sm btn-soft-primary" id={`viewtooltip-${cellProps.row.original.id}`} onClick={() => handleModal(cellProps.row.original)}>
                                    <i className="mdi mdi-eye-outline" />
                                </Link>
                            </li>
                            <UncontrolledTooltip placement="top" target={`viewtooltip-${cellProps.row.original.id}`}>
                                View
                            </UncontrolledTooltip>
                            <li>
                                <Link
                                    to={`/client/edit/${cellProps.row.original.id}`}
                                    className="btn btn-sm btn-soft-info"

                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        [page]
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this client?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Client Management" breadcrumbItem="Client" />
                    {
                        // isLoading ? <Spinners setLoading={setLoading} />
                        //     :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Client</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="/client/add" className="btn btn-primary me-1">Add</Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <Row className="mb-2">
                                            <Col sm={4}>
                                                <input value={search} onChange={e => {
                                                    setSearch(e.target.value);
                                                    setPage(1)
                                                }} type="text" placeholder="Your Search" className="form-control search-box me-2 mb-2 d-inline-block" />{" "}

                                            </Col>
                                            <Col sm={2}>     <Button type="button" onClick={handleSearch} color="primary">Search</Button></Col>
                                        </Row>
                                        <TableContainer
                                            columns={columns}
                                            data={clients || []}
                                            loading={loading}
                                            setLoading={setLoading}

                                            isGlobalFilter={false}
                                            // isJobListGlobalFilter={true}
                                            isPagination={false}
                                            SearchPlaceholder="Search for ..."
                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"

                                        />

                                        {
                                            totalCount > 0 &&
                                            <Paginations
                                                perPageData={limit}
                                                data={clients}
                                                currentPage={page}
                                                setCurrentPage={setPage}
                                                isShowingPageLength={true}
                                                totalCount={totalCount}
                                                paginationClass="pagination"
                                                paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"

                                            />
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Contact Details {!isEmpty(selectedItem) ? ` of ${selectedItem?.client_name}` : ''}</ModalHeader>
                <ModalBody>

                <UncontrolledAccordion defaultOpen="aa-0">

                {
                      selectedItem?.clientContact?.length>0 ?  selectedItem?.clientContact?.map((item, index) => <AccordionItem key={`accord-${index}`}>
                        <AccordionHeader targetId={`aa-${index}`}>{index+1}. {item?.fname} {" "} {item?.lname} </AccordionHeader>
                        <AccordionBody accordionId={`aa-${index}`}>
                          <div className="text-muted">
                          <div className="d-flex">
                                    <strong> Email :</strong><p className="px-2">{item?.email}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Primary Phone Number :</strong><p className="px-2">{item?.ph_no1}</p>
                                </div>
                                <div className="d-flex">
                                    <strong> Secondary Phone Number :</strong><p className="px-2">{item?.ph_no2}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Whatsapp Number :</strong><p className="px-2">{item?.whatsapp_number}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Birthday :</strong><p className="px-2">{item?.bday? moment(item?.bday).format('DD MMM, YYYY'):'N/A'}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Anniversary Day :</strong><p className="px-2">{item?.aday? moment(item?.aday).format('DD MMM, YYYY'):'N/A'}</p>
                                </div>
                                

                          </div>
                        </AccordionBody>
                      </AccordionItem>
                        
                        
                        
                     


                         )

                         : 
                         <AccordionItem className="notshowAcoordionIcon">
                         <AccordionHeader >No Contact Available </AccordionHeader>
                         
                       </AccordionItem>
                         

                    }
                       
                   
                      </UncontrolledAccordion>

                 



                </ModalBody>

            </Modal>
            <ToastContainer />
        </React.Fragment>
    );
}


export default ClientList;